import React, { FC, ReactNode, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";
import EmptyComponent from "./EmptyComponent";
import version from "../version";

const BugsnagComponent: FC<{ children: ReactNode; isProduction: boolean }> = ({
  children,
  isProduction,
}) => {
  const [isBugsnagStarted, setIsBugsnagStarted] = useState(false);

  useEffect(() => {
    if (!isProduction) {
      return;
    }

    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || "",
      plugins: [new BugsnagPluginReact()],
      appVersion: `${version}`,
    });

    setIsBugsnagStarted(true);
  }, [isProduction]);

  let ErrorBoundary: typeof EmptyComponent | BugsnagErrorBoundary =
    EmptyComponent;

  if (isProduction && isBugsnagStarted) {
    ErrorBoundary =
      Bugsnag.getPlugin("react")?.createErrorBoundary(React) || EmptyComponent;
  }

  return (
    <>
      {isProduction ? (
        <ErrorBoundary>{children}</ErrorBoundary>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default BugsnagComponent;
