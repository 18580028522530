import React, { ReactElement } from "react";
import type { AppProps } from "next/app";
import { createGlobalStyle } from "styled-components";
import Helmet from "react-helmet";
import BugsnagComponent from "../components/BugsnagComponent";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Forum;
    src: url('/fonts/Forum-Regular.ttf');
    font-display: block;
  }

  @font-face {
    font-family: Playball-Regular;
    src: url('/fonts/Playball-Regular.ttf');
    font-display: block;
  }

  @font-face {
    font-family: BerkshireSwash-Regular;
    src: url('/fonts/BerkshireSwash-Regular.ttf');
    font-display: block;
  }

  html,
  body,
  button {
    font-family: "Forum", cursive;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;

    margin: 0;
    padding: 0;
  }
`;

export default function App({ Component, pageProps }: AppProps): ReactElement {
  const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === "production";

  return (
    <BugsnagComponent isProduction={isProduction}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta property="og:site_name" content="Sus i Serken" />

        {isProduction && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG}`}
          ></script>
        )}

        {isProduction && (
          <script type="text/javascript">
            {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag("js", new Date());

                  gtag("config", "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG}");
              `}
          </script>
        )}
      </Helmet>

      <GlobalStyle />

      <Component {...pageProps} />
    </BugsnagComponent>
  );
}
